
<script setup>

import {computed, defineEmits }   from 'vue';

const emits = defineEmits(['select-document-numbers']);

const props = defineProps({
  documentInfoList: Object,
});


const selectDocumentNumber = (index) => {
  emits('select-document-number', index);
}


</script>
<template>
  <div class="px-4 sm:px-6 lg:px-8">

    <div class="mt-8 flow-root">
      <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <table class="min-w-full divide-y divide-gray-300">
            <thead>
            <tr>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">#</th>
              <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">Document</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Owner</th>
              <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Company Type</th>
              <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span class="sr-only">Order</span>
              </th>
            </tr>
            </thead>
            <tbody class="divide-y divide-gray-200">
            <tr v-for="(document, key) in documentInfoList" :key="key">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ key +1 }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ document.document_number }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ document.company.name }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ document.owner.first_name }} {{ document.owner.last_name }}</td>
              <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ document.owner.first_name }} {{ document.owner.last_name }}</td>
              <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                <button @click="selectDocumentNumber(key)" type="button" class="rounded-md bg-emerald-500 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Select</button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

</template>
