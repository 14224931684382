<script setup>
import {computed, onMounted, ref, watch} from 'vue';
import {Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions} from '@headlessui/vue'
import {CheckIcon, ChevronUpDownIcon} from '@heroicons/vue/20/solid'

const emit = defineEmits(['update:modelValue']);


const props = defineProps({
  checked: {
    type: Boolean,
    default: false,
  },
  value: {
    type: String,
    default: null,
  },
  labelOn: {
    type: String,
    default: null,
  },
  labelOff: {
    type: String,
    default: null,
  },
  values: {
    type: Array,
    default: {},
  },
});

const toggleSeeDeleteLabel = ref('Show Deleted');

const calculateSeeDeleteLabel = () => {
  if (props.checked) {
    toggleSeeDeleteLabel.value = props.labelOn;

  } else {
    toggleSeeDeleteLabel.value = props.labelOff;
  }
}


const proxyChecked = computed({
  get() {

    return props.checked;
  },

  set(val) {
    // emit('update:checked', val);
  },
});

const emitSelectedValue = () => {
  console.log('emit 2  value in dropdown.' +  selected.value.name);
  emit('update:modelValue', selected.value.name);
};
const valuelist = props.values;

const selected = ref(valuelist[0]);

watch(selected, (newValue, oldValue) => {
  console.log('emit value in dropdown.' + newValue.name);
  emitSelectedValue();

});

onMounted(() => {
  emitSelectedValue();
});

</script>
<template>
<div  class="w-full">

  <label for="label" class="block text-sm font-medium text-gray-700">{{ props.labelOn }}</label>
  <div class="mt-1">


    <Listbox as="div" v-model="selected" @change="emitSelectedValue()">
      <ListboxButton
          class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
        <span class="block truncate">{{ selected.name }}</span>
        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
          <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true"/>
        </span>
      </ListboxButton>

      <transition leave-active-class="transition ease-in duration-100" leave-from-class="opacity-100"
                  leave-to-class="opacity-0">
        <ListboxOptions
            class="absolute z-10 mt-1 max-h-60 w-full max-w-md overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          <ListboxOption as="template" v-for="value in valuelist" :key="values.id" :value="value"
                         v-slot="{ active, selected }">
            <li :class="[active ? 'bg-indigo-600 text-white' : 'text-gray-900', 'relative cursor-default select-none py-2 pl-3 pr-9']">
              <span :class="[selected ? 'font-semibold' : 'font-normal']">{{ value.name }}</span>

              <span v-if="selected"
                    :class="[active ? 'text-white' : 'text-indigo-600', 'absolute inset-y-0 right-0 flex items-center pr-4']">
                <CheckIcon class="h-5 w-5" aria-hidden="true"/>
              </span>
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </Listbox>
  </div>
</div>
</template>

<style>

</style>

