<script setup>


import DocumentSearch from '@/partials/DocumentSearch.vue'
import DocumentInfo from '@/partials/DocumentInfo.vue'
import DocumentInfoList from '@/partials/DocumentInfoList.vue'
import FormBoirSection from '@/partials/Products/FormBoirSection.vue'
import FormEinSection from '@/partials/Products/FormEinSection.vue'
import {defineEmits, onMounted, onBeforeUnmount, watch, reactive, toRaw, ref, computed} from "vue";
import {useForm} from "laravel-precognition-vue-inertia";
import axios from 'axios';
import {TrashIcon} from "@heroicons/vue/20/solid";
import moment from 'moment';
import {VueSignaturePad} from 'vue-signature-pad';

const props = defineProps({
  companyDocumentProp: {
    type: Object,
    required: false,
    default: null,
  },
  productProp: {
    type: Object,
    required: false,
    default: null,
  },

})


const companyDocument = ref(props.companyDocumentProp);
const product = ref(props.productProp);
const documentItem = ref(null);
const children = ref([]);
const finished = ref(false);
const addEinSection = ref(false);
const addBoirSection = ref(false);
const tos = ref(null);
const loading = ref(false);

const items = ref([]);
const errorsPresent = ref(false);


const total = computed(() => {
  let calculatedTotal = 0;

  for (let i = 0; i < items.value.length; i++) {
    calculatedTotal = Number(calculatedTotal) + Number(items.value[i].price);

  }
  return calculatedTotal;

});

const form = reactive({
// const form = useForm('post', route('public.api.submit.order'), {
  first_name: companyDocument.value.owner.first_name,
  last_name: companyDocument.value.owner.last_name,
  email: companyDocument.value.owner.email,
  document_uuid: companyDocument.value.uuid,
  address: companyDocument.value.owner.addresses[0].address,
  city: companyDocument.value.owner.addresses[0].city,
  state: companyDocument.value.owner.addresses[0].state,
  zip: companyDocument.value.owner.addresses[0].zip,
  country: companyDocument.value.owner.addresses[0].country,
  phone: companyDocument.value.owner.phone,
  company_name: companyDocument.value.company.name,
  company_address: companyDocument.value.company.addresses[0],
  items: items.value,
  add_boir_section: false,
  add_ein_section: false,
  tos: null,
  boir: {
    tax_identification_type: null,
    tax_identification_id: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    company_name: null,
    company_dba: null,
    receive_fincen_id: false,
    applicants: [],
    beneficiaries: [],
    company_address: {address: null, city: null, state: null, zip: null},
  },
  ein: {
    ein_first_name: null,
    ein_last_name: null,
    ein_county: null,
    ein_ssn: null,
    ein_member_number: null,
    ein_merchandise: null,
    ein_date_start: null,
    ein_entity_type: null,
    ein_before: 0,
    ein_before_specified: null,
    ein_reason: null,
    ein_activity: null,
    ein_further_info: null,
    ein_entity_type_specified: null,
    ein_entity_type_corporation_specified: null,
    question: {heavy_vehicle: null, gambling: null, w2: null, guns: null, quarterly: null},
    signature_fss4: null,
    signature_f8821: null,
  },
  errors: {
    email: null,
    first_name: null,
    last_name: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    phone: null,
    ein_first_name: null,
    ein_last_name: null,
    ein_county: null,
    ein_ssn: null,
    ein_member_number: null,
    ein_merchandise: null,
    ein_date_start: null,
    ein_activity_specified: null,
    ein_before: null,
    ein_before_specified: null,
    tos: null,
    ein_further_info: null,
    company_name: null,
    boir: {
      tax_identification_type: null,
      tax_identification_id: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      company_name: null,
      company_dba: null,
      receive_fincen_id: false,
      applicants: [{
        last_name: null,
        isMinor: null,
        first_name: null,
        dob: null,
        address: null,
        city: null,
        country: null,
        id_jurisdiction_country: null,
        id_jurisdiction_state: null,
        state: null,
        zip: null,
        document_type: null,
        document_id: null,
        document_region: null,
        document_image: null,

      }],
      beneficiaries: [
        {
          last_name: null,
          isMinor: null,
          first_name: null,
          dob: null,
          address: null,
          city: null,
          country: null,
          id_jurisdiction_country: null,
          id_jurisdiction_state: null,
          state: null,
          zip: null,
          document_type: null,
          document_id: null,
          document_region: null,
          document_image: null,

        }
      ],
      company_address: {address: null, city: null, state: null, zip: null},
    }
  },

});

function resetErrors() {

  form.errors = {
    email: null,
    first_name: null,
    last_name: null,
    address: null,
    city: null,
    state: null,
    zip: null,
    country: null,
    phone: null,
    ein_first_name: null,
    ein_last_name: null,
    ein_county: null,
    ein_ssn: null,
    ein_member_number: null,
    ein_merchandise: null,
    ein_date_start: null,
    ein_activity_specified: null,
    ein_before: null,
    ein_before_specified: null,
    tos: null,
    ein_further_info: null,
    company_name: null,
    boir: {
      tax_identification_type: null,
      tax_identification_id: null,
      address: null,
      city: null,
      state: null,
      zip: null,
      company_name: null,
      company_dba: null,
      receive_fincen_id: false,
      applicants: [{
        last_name: null,
        isMinor: null,
        first_name: null,
        dob: null,
        address: null,
        city: null,
        country: null,
        id_jurisdiction_country: null,
        id_jurisdiction_state: null,
        state: null,
        zip: null,
        document_type: null,
        document_id: null,
        document_region: null,
        document_image: null,
      }],
      beneficiaries: [
        {
          last_name: null,
          isMinor: null,
          first_name: null,
          dob: null,
          address: null,
          city: null,
          country: null,
          id_jurisdiction_country: null,
          id_jurisdiction_state: null,
          state: null,
          zip: null,
          document_type: null,
          document_id: null,
          document_region: null,
          document_image: null,
        }
      ],
      company_address: {address: null, city: null, state: null, zip: null},
    }
  };

}


function addChildItems(index) {

  items.value.push({
    id: children.value[index].id,
    uuid: children.value[index].uuid,
    title: children.value[index].name,
    code: children.value[index].code,
    href: '#',
    price: children.value[index].price,
    priceId: children.value[index].priceId,
    subTitle: companyDocument.value.company.name,
    description: children.value[index].description,
    imageSrc: '../../images/certificate_of_status_florida.png',
    imageAlt: "Certified Copy",
  })

  if (children.value[index].code == 'ein_registration') {
    addEinSection.value = true;
    form.add_ein_section = true;
  }
  if (children.value[index].code == 'boir') {
    addBoirSection.value = true;
    form.add_boir_section = true;
  }

}

function hasChildItem(name) {

  for (let i = 0; i < items.value.length; i++) {
    if (items.value[i].title == name) {
      return true;
    }
  }
  return false;

}

function removeEin() {

  for (let i = 0; i < items.value.length; i++) {
    if (documentItem.value.code != 'ein_registration' && items.value[i].code == 'ein_registration') {
      addEinSection.value = false;
      form.add_ein_section = false;
      items.value.splice(i, 1);
    }

  }
  return false;
}

function removeBoir() {

  for (let i = 0; i < items.value.length; i++) {
    if (documentItem.value.code != 'boir' && items.value[i].code == 'boir') {
      addBoirSection.value = false;
      form.add_boir_section = false;
      items.value.splice(i, 1);
    }

  }
  return false;

}

function deleteChild(code) {

  for (let i = 0; i < items.value.length; i++) {
    if (items.value[i].code == code) {

      if (documentItem.value.code != 'ein_registration' && items.value[i].code == 'ein_registration') {
        addEinSection.value = false;
        form.add_ein_section = false;
      }
      if (documentItem.value.code != 'boir' && items.value[i].code == 'boir') {
        console.log('test 1');
        addBoirSection.value = false;
        form.add_boir_section = false;
      }
      items.value.splice(i, 1);
    }
  }

}


async function getPrimaryDocumentItem() {

  let productCode = null;
  if (product.value && product.value.code) {
    productCode = product.value.code;
  } else {
    productCode = 'certificate'
  }

  await axios
      .post(route('public.api.get.parent.item'), {product_code: productCode})
      .then(response => {
        documentItem.value = response.data.data;
      })

  items.value.push({
    id: documentItem.value.id,
    uuid: documentItem.value.uuid,
    title: documentItem.value.name,
    href: '#',
    price: documentItem.value.price,
    priceId: documentItem.value.priceId,
    subTitle: companyDocument.value.company.name,
    description: documentItem.value.description,
    imageSrc: '../../images/certificate_of_status_florida.png',
    imageAlt: documentItem.value.id.name,
  });
  // Define the children.
  children.value = documentItem.value.children;

  if (documentItem.value.code == 'ein_registration') {
    addEinSection.value = true;
    form.add_ein_section = true;

  }
  if (documentItem.value.code == 'boir') {
    addBoirSection.value = true;
    form.add_boir_section = true;

  }

}

const updateBOIRForm = (updatedForm) => {
  console.log('updateBOIRForm');
  console.log('form BEFORE');
  console.log(form);
  form.boir = updatedForm;
  console.log('form AFTER');
  console.log(form);

  removeErrorsOnUpdate();
}
const updateEINForm = (updatedForm) => {

  console.log('updateEINForm');

  form.ein = updatedForm;
  removeErrorsOnUpdate();
}
const removeErrorsOnUpdate = () => {

  if (Array.isArray(form.boir.applicants)) {
    for (let i = 0; i < form.boir.applicants.length; i++) {
      if (form.boir.applicants[i].document_image) {
        form.errors['boir.applicants.' + i + '.document_image'] = null;
      }
      if (form.boir.applicants[i].first_name) {
        form.errors['boir.applicants.' + i + '.first_name'] = null;
      }
      if (form.boir.applicants[i].last_name) {
        form.errors['boir.applicants.' + i + '.last_name'] = null;
      }
      if (form.boir.applicants[i].address) {
        form.errors['boir.applicants.' + i + '.address'] = null;
      }
      if (form.boir.applicants[i].city) {
        form.errors['boir.applicants.' + i + '.city'] = null;
      }
      if (form.boir.applicants[i].state) {
        form.errors['boir.applicants.' + i + '.state'] = null;
      }
      if (form.boir.applicants[i].zip) {
        form.errors['boir.applicants.' + i + '.zip'] = null;
      }
      if (form.boir.applicants[i].dob) {
        form.errors['boir.applicants.' + i + '.dob'] = null;
      }
      if (form.boir.applicants[i].identificationId) {
        form.errors['boir.applicants.' + i + '.identificationId'] = null;
      }
      if (form.boir.applicants[i].document_id) {
        form.errors['boir.applicants.' + i + '.document_id'] = null;
      }
      if (form.boir.applicants[i].id_jurisdiction_country) {
        form.errors['boir.applicants.' + i + '.id_jurisdiction_country'] = null;
      }
      if (form.boir.applicants[i].id_jurisdiction_state) {
        form.errors['boir.applicants.' + i + '.id_jurisdiction_state'] = null;
      }
    }
  }
  if (Array.isArray(form.boir.beneficiaries)) {
    for (let i = 0; i < form.boir.beneficiaries.length; i++) {
      if (form.boir.beneficiaries[i].document_image) {
        form.errors['boir.beneficiaries.' + i + '.document_image'] = null;
      }
      if (form.boir.beneficiaries[i].first_name) {
        form.errors['boir.beneficiaries.' + i + '.first_name'] = null;
      }
      if (form.boir.beneficiaries[i].last_name) {
        form.errors['boir.beneficiaries.' + i + '.last_name'] = null;
      }
      if (form.boir.beneficiaries[i].address) {
        form.errors['boir.beneficiaries.' + i + '.address'] = null;
      }
      if (form.boir.beneficiaries[i].city) {
        form.errors['boir.beneficiaries.' + i + '.city'] = null;
      }
      if (form.boir.beneficiaries[i].state) {
        form.errors['boir.beneficiaries.' + i + '.state'] = null;
      }
      if (form.boir.beneficiaries[i].zip) {
        form.errors['boir.beneficiaries.' + i + '.zip'] = null;
      }
      if (form.boir.beneficiaries[i].dob) {
        form.errors['boir.beneficiaries.' + i + '.dob'] = null;
      }
      if (form.boir.beneficiaries[i].identificationId) {
        form.errors['boir.beneficiaries.' + i + '.identificationId'] = null;
      }
      if (form.boir.beneficiaries[i].document_id) {
        form.errors['boir.beneficiaries.' + i + '.document_id'] = null;
      }
      if (form.boir.beneficiaries[i].id_jurisdiction_country) {
        form.errors['boir.beneficiaries.' + i + '.id_jurisdiction_country'] = null;
      }
      if (form.boir.beneficiaries[i].id_jurisdiction_state) {
        form.errors['boir.beneficiaries.' + i + '.id_jurisdiction_state'] = null;
      }
    }
  }

  // Order Page
  if (form.first_name) {
    form.errors.first_name = null;
  }
  if (form.last_name) {
    form.errors.last_name = null;
  }
  if (form.phone) {
    form.errors.phone = null;
  }
  if (form.email) {
    form.errors.email = null;
  }
  if (form.company_name) {
    form.errors.company_name = null;
  }
  if (form.address) {
    form.errors.address = null;
  }
  if (form.city) {
    form.errors.city = null;
  }
  if (form.state) {
    form.errors.state = null;
  }
  if (form.zip) {
    form.errors.zip = null;
  }
  if (form.tos) {
    form.errors.tos = null;
  }
  // EIN Page
  if (form.ein.ein_first_name) {
    form.errors['ein.ein_first_name'] = null;
  }
  if (form.ein.ein_last_name) {
    form.errors['ein.ein_last_name'] = null;
  }
  if (form.ein.ein_county) {
    form.errors['ein.ein_county'] = null;
  }
  if (form.ein.ein_ssn) {
    form.errors['ein.ein_ssn'] = null;
  }
  if (form.ein.ein_entity_type) {
    form.errors['ein.ein_entity_type'] = null;
  }
  if (form.ein.ein_entity_type_corporation_specified) {
    form.errors['ein.ein_entity_type_corporation_specified'] = null;
  }
  if (form.ein.ein_entity_type_specified) {
    form.errors['ein.ein_entity_type_specified'] = null;
  }
  if (form.ein.ein_reason) {
    form.errors['ein.ein_reason'] = null;
  }
  if (form.ein.ein_date_start) {
    form.errors['ein.ein_date_start'] = null;
  }
  if (form.ein.ein_activity) {
    form.errors['ein.ein_activity'] = null;
  }
  if (form.ein.ein_activity_specified) {
    form.errors['ein.ein_activity_specified'] = null;
  }
  if (form.ein.ein_merchandise) {
    form.errors['ein.ein_merchandise'] = null;
  }
  if (form.ein.ein_before) {
    form.errors['ein.ein_before'] = null;
  }
  if (form.ein.ein_before_specified) {
    form.errors['ein.ein_before_specified'] = null;
  }
  if (form.ein.ein_further_info) {
    form.errors['ein.ein_further_info'] = null;
  }
  if (form.ein.question.heavy_vehicle) {
    form.errors['ein.question.heavy_vehicle'] = null;
  }
  if (form.ein.question.gambling) {
    form.errors['ein.question.gambling'] = null;
  }
  if (form.ein.question.quarterly) {
    form.errors['ein.question.quarterly'] = null;
  }
  if (form.ein.question.guns) {
    form.errors['ein.question.guns'] = null;
  }
  if (form.ein.question.w2) {
    form.errors['ein.question.w2'] = null;
  }
  if (form.ein.signature_fss4) {
    form.errors['ein.signature_fss4'] = null;
  }
  if (form.ein.signature_f8821) {
    form.errors['ein.signature_f8821'] = null;
  }


}


const clearTos = () => {
  form.tos = null;
  removeErrorsOnUpdate();
}


const handleTosClick = () => {

  form.tos = !form.tos;
  removeErrorsOnUpdate();

}

const submit = async() => {
  errorsPresent.value = false;
  loading.value = true;
  // form.errors = null;
  resetErrors();


  await axios.post(route('public.api.submit.order'), form)
      .then(response => {
        if (response.data.data.result == 'success') {

          window.location.href = response.data.data.url;
          // finished.value = true;
        }

      })
      .catch(error => {
        // Handle error here

        if (!(error.response.data.errors === undefined)) {
          form.errors = {
            ...form.errors,
            ...error.response.data.errors
          };
          // form.errors = error.response.data.errors;
          // form.errors
          errorsPresent.value = true;
          console.log('form.errors');
          console.log(form.errors);
        }

      });
  loading.value = false;

};


onMounted(() => {
  getPrimaryDocumentItem();


});


</script>

<template>

  <div class="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
    <div v-if="!finished">
      <form @submit.prevent="submit" class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>
          <div class="mt-0 pt-0">
            <h2 class="text-lg font-medium text-gray-900">Mailing & Billing Information</h2>

            <div class="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
              <div>
                <label for="first-name" class="block text-sm font-medium text-gray-700">First name</label>
                <div class="mt-1">
                  <input @blur="removeErrorsOnUpdate"
                         :class="{'border-2 border-red-500': form.errors.first_name}" v-model="form.first_name"
                         type="text" id="first-name" name="first-name" autocomplete="given-name"
                         class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.first_name" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.first_name" :key="index">
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <label for="last-name" class="block text-sm font-medium text-gray-700">Last name</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      :class="{'border-2 border-red-500': form.errors.last_name}" v-model="form.last_name"
                      type="text" id="last-name" name="last-name" autocomplete="family-name"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.last_name" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.last_name" :key="index">
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div class="sm:col-span-2">
                <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
                <div class="mt-1">
                  <input @blur="removeErrorsOnUpdate"
                         :class="{'border-2 border-red-500': form.errors.phone}" v-model="form.phone" type="text"
                         name="phone" id="phone" autocomplete="tel"
                         class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.phone" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.phone" :key="index">{{
                          error
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="sm:col-span-2">
                <label for="email-address1" class="block text-sm font-medium text-gray-700">Email address</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      :class="{'border-2 border-red-500': form.errors.email}" v-model="form.email" type="email"
                      id="email-address1" name="email-address" autocomplete="email"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.email" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.email" :key="index">{{
                          error
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="company" class="block text-sm font-medium text-gray-700">Company</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      v-model="form.company_name" type="text" readonly name="company" id="company"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                </div>
              </div>

              <div class="sm:col-span-2">
                <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      :class="{'border-2 border-red-500': form.errors.address}" v-model="form.address" type="text"
                      name="address" id="address" autocomplete="street-address"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.address" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.address" :key="index">
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div>
                <label for="city" class="block text-sm font-medium text-gray-700">City</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      :class="{'border-2 border-red-500': form.errors.city}" v-model="form.city" type="text"
                      name="city" id="city" autocomplete="address-level2"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.city" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.city" :key="index">{{
                          error
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


              <div>
                <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      :class="{'border-2 border-red-500': form.errors.state}" v-model="form.state" type="text"
                      name="region" id="region" autocomplete="address-level1"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.state" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.state" :key="index">{{
                          error
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div>
                <label for="postal-code" class="block text-sm font-medium text-gray-700">Postal code</label>
                <div class="mt-1">
                  <input
                      @blur="removeErrorsOnUpdate"
                      :class="{'border-2 border-red-500': form.errors.zip}" v-model="form.zip" type="text"
                      name="postal-code" id="postal-code" autocomplete="postal-code"
                      class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
                  <div v-if="form.errors.zip" class="m-2">
                    <ul>
                      <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.zip" :key="index">{{
                          error
                        }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>


            </div>
          </div>


          <FormEinSection
              v-if="addEinSection"
              :companyDocumentProp="companyDocument"
              :formData="form"
              :formErrors="form.errors"
              @remove="removeEin()"
              @updateFormData="updateEINForm"
          ></FormEinSection>


          <FormBoirSection
              v-if="addBoirSection"
              :formData="form"
              :formErrors="form.errors"
              :addEinSectionProp="form.add_ein_section"

              @remove="removeBoir()"
              @updateFormData="updateBOIRForm"

          ></FormBoirSection>

          <div class="sm:col-span-2 mt-6">
            <h2 class="text-lg font-medium text-gray-900">Accept Terms and Conditions</h2>
          </div>
          <div class="sm:col-span-2 mt-4">

            <div
                class="container mx-auto bg-white 4 rounded-lg shadow-lg border h-64 overflow-y-auto p-4">
              <h1 class=" font-bold mb-2">Terms and Conditions</h1>
              <p class="mb-2 text-gray-700 text-sm">
                Welcome to Business Certificate Services Florida LLC's website/application ("Platform"). By using our
                Platform you agree to comply with and be bound by the following terms and conditions. Please read these
                Terms and Conditions carefully. If you do not agree with these Terms and Conditions, please do not use
                our Platform.
              </p>

              <h2 class="font-semibold mb-2">Eligibility</h2>
              <p class="mb-2 text-gray-700 text-sm">
                You must be at least 18 years old to use our Platform or otherwise have the power and authority to enter
                into and perform under these Terms and Conditions.
              </p>

              <h2 class="font-semibold mb-2">Services</h2>
              <p class="mb-2 text-gray-700 text-sm">
                Business Certificate Services Florida LLC provides assistance in preparing and filing for many different services on behalf of our clients ("Service"). We act as a third-party designee to help you fill out and file the necessary forms and paperwork.
              </p>
              <h2 class="font-semibold mb-2">Limitation of Liability</h2>
              <p class="mb-2 text-gray-700 text-sm">
                Business Certificate Services Florida LLC is not affiliated with the Internal Revenue Service (IRS) or
                any other governmental agency. We are not a law firm, CPA firm, or financial advisory firm, and the
                Services provided are not a substitute for legal or professional advice. You should consult appropriate
                professionals for such advice tailored to your specific situation.
              </p>

              <h2 class="font-semibold mb-2">Notifications (Phone, Email, and SMS Text Messaging</h2>
              <p class="mb-2 text-gray-700 text-sm">
               You agree to receive notifications from BusinessCertificateServices.com via phone, email, and sms text messages. You may opt out of these messages my emailing us at support@BusinessCertificateServices.com
              </p>
              <h2 class="font-semibold mb-2">Fees and Payment</h2>
              <p class="mb-2 text-gray-700 text-sm">
                The fees for our Service are specified on our Platform. All fees are payable upfront and are
                non-refundable, except as explicitly specified herein.
              </p>
              <h2 class="font-semibold mb-2">Accuracy of Information</h2>
              <p class="mb-2 text-gray-700 text-sm">
                You are responsible for providing accurate and complete information when using our Service. Business
                Certificate Services Florida LLC is not responsible for any errors or omissions, or for the results
                obtained from the use of this information.
              </p>
              <h2 class="font-semibold mb-2">Data Privacy</h2>
              <p class="mb-2 text-gray-700 text-sm">
                Please refer to our Privacy Policy for information on how we collect, use, and disclose your personal
                information.
              </p>
              <h2 class="font-semibold mb-2">Termination</h2>
              <p class="mb-2 text-gray-700 text-sm">
                Business Certificate Services Florida LLC reserves the right, in its sole discretion, to terminate your
                access to the Platform and the Services, with or without notice. Typically, we will terminate your
                account for any violation of these Terms and Conditions or for failure to pay any fees due.
              </p>
              <h2 class="font-semibold mb-2">Changes to Terms and Conditions</h2>
              <p class="mb-2 text-gray-700 text-sm">
                We reserve the right to change, modify, or revise these Terms and Conditions at any time. Any changes
                will be effective immediately upon posting on the Platform, and you waive any right you may have to
                receive specific notice of such changes. It is your responsibility to review these Terms and Conditions
                regularly.
              </p>
              <h2 class="font-semibold mb-2">Governing Law</h2>
              <p class="mb-2 text-gray-700 text-sm">
                These Terms and Conditions shall be governed by and construed in accordance with the laws of the State
                of Florida, United States of America, without giving effect to its conflict of laws provisions.
              </p>
              <h2 class="font-semibold mb-2">Contact</h2>
              <p class="mb-2 text-gray-700 text-sm">
                For any questions regarding these Terms and Conditions, please contact us at
                support@businesscertificatesservices.com.
              </p>

              <h2 class="font-semibold mb-2">Acceptance of Terms</h2>
              <p class="mb-4 text-gray-700 text-sm">
                By using the Platform, you signify your acceptance of these Terms and Conditions.
              </p>

            </div>


            <div class="sm:col-span-2 mt-6">

              <div class="sm:col-span-2 mt-6">
                <h2 class="text-lg font-medium mt-2">Sign the acceptance of the terms, conditions, and refund
                  policy.</h2>
                <label for="checkbox-1" class="ml-2  text-md font-medium ">By signing below you agree to the terms,
                  conditions, and refund policy.</label>
              </div>


              <div class="sm:col-span-2 mt-6">

                <input
                    @blur="removeErrorsOnUpdate"
                    @change="handleTosClick" v-model="tos" id="checkbox-1" type="checkbox"
                    class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                <label for="checkbox-1" class="ml-2  text-md font-medium ">I agree to the above terms and
                  condition.</label>
                <div v-if="form.errors.tos" class="m-2">
                  <ul>
                    <li class="text-red-600 text-sm" v-for="(error, index) in form.errors.tos"
                        :key="index">{{
                        error
                      }}
                    </li>
                  </ul>
                </div>
              </div>


            </div>


          </div>
        </div>

        <!-- Order summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900">Order Summary</h2>

          <div class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <ul role="list" class="divide-y divide-gray-200">
              <li v-for="(product,index) in items" :key="index" class="flex px-4 py-6 sm:px-6">
                <div class="flex-shrink-0">
                  <img :src="product.imageSrc" :alt="product.imageAlt" class="w-20 rounded-md"/>
                </div>

                <div class="ml-6 flex flex-1 flex-col">
                  <div class="flex">
                    <div class="min-w-0 flex-1">
                      <h4 class="text-sm">
                        <a :href="product.href" class="font-medium text-gray-700 hover:text-gray-800">{{
                            product.title
                          }}</a>
                      </h4>
                      <p class="mt-1 text-sm text-gray-500">{{ product.subTitle }}</p>
                      <p class="mt-1 text-sm text-gray-500">{{ product.description }}</p>
                    </div>

                    <div v-if="index!==0" class="ml-4 flow-root flex-shrink-0">
                      <button type="button"
                              class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Remove</span>
                        <TrashIcon @click="deleteChild(product.code)" class="h-5 w-5" aria-hidden="true"/>
                      </button>
                    </div>
                  </div>


                  <div class="flex flex-1 items-end justify-between pt-2">
                    <p class="mt-1 text-sm font-medium text-gray-900">$ {{ product.price }}</p>
                  </div>
                </div>
              </li>

            </ul>
            <div class="bg-indigo-500 text-white p-1 text-center mb-2">OTHER OPTIONAL BUSINESS SERVICES</div>

            <div v-for="(child,index) in children" :key="index" class="flex text-sm ml-4 mb-4 text-indigo-600">
              <button v-if="!hasChildItem(child.name)" @click="addChildItems(index)" class="flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span v-if="child.code=='ein_registration' || child.code=='boir'" class="font-bold text-red-500 mr-1">CRITICAL: </span>  Add {{ child.name }} + ${{ child.price }}

              </button>
            </div>

            <dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div class="flex items-center justify-between">
                <dt class="text-sm">Subtotal</dt>
                <dd class="text-sm font-medium text-gray-900">$ {{ total.toFixed(2) }}</dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Shipping</dt>
                <dd class="text-sm font-medium text-gray-900">$0.00</dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Taxes</dt>
                <dd class="text-sm font-medium text-gray-900">$0.00</dd>
              </div>
              <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt class="text-base font-medium">Total</dt>
                <dd class="text-base font-medium text-gray-900">$ {{ total.toFixed(2) }}</dd>
              </div>
            </dl>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
              <button type="button"
                      class="inline-flex justify-center text-xl text-center uppercase w-full inline-flex items-center rounded-md bg-indigo-600 px-4 py-3 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                      @click="submit"
                      :disabled="loading"
              >
                <svg v-if="loading" aria-hidden="true" class="mr-2 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                  <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                </svg>
                <span v-if="!loading">Submit Order</span>
                <span v-else>Submitting</span>
              </button>


              <div v-if="errorsPresent" class="mt-4 border-2 border-red-500 text-red-600 p-3">Oops. There are some
                errors that need your attention. Review the issues highlighted in red and re-submit.
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div v-else>
      <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div class="fixed inset-0 z-10 overflow-y-auto">
          <div class="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">

            <div
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
              <div>
                <div class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                  <svg class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                       stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5"/>
                  </svg>
                </div>
                <div class="mt-3 text-center sm:mt-5">
                  <h3 class="text-base font-semibold leading-6 text-gray-900" id="modal-title">Order Entered
                    Successfully</h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500">The order has been created successfully. </p>
                  </div>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <a :href="route('admin.orders.index')">
                  <button type="button"
                          class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Go back to dashboard
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

