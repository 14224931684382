<script setup>


import DocumentSearch from '@/partials/DocumentSearch.vue'
import DocumentInfo from '@/partials/DocumentInfo.vue'
import DocumentInfoList from '@/partials/DocumentInfoList.vue'
import {defineEmits, onMounted, reactive, ref} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import axios from 'axios';


const emits = defineEmits(['select-document-number']);

const props = defineProps({
  documentNumberProp: {
    type: String,
  },loading: {
    type: Boolean,
    required: false,
    default: false
  },


})

const documentNumber = ref(null)
const loading = ref(false)
const documentInfoList = ref([])
const selectedDocument = ref(null)



const getDocumentInfo = async () => {
  loading.value = true;
  await axios
      .post(route('public.api.getDocumentInfo'), { documentNumber: documentNumber.value })
      .then(response => {
        documentInfoList.value = response.data.data;
        console.log('documentInfoList.value 44');
        console.log(documentInfoList.value);
        console.log(documentInfoList.value.length == 1);

        if (documentInfoList.value.length == 1) {
          selectedDocument.value = documentInfoList.value[0];
          console.log('selectedDocument.value 2');
          console.log(selectedDocument.value);
        } else {
          console.log('selectedDocument.value = null');

          selectedDocument.value = null;
        }
        console.log('end getDocumentInfo');
      })
  loading.value = false;
}



const updateDocumentNumber = (documentNumberText) => {
  console.log('updateDocumentNumber Parent')
  console.log(documentNumberText)
  documentNumber.value = documentNumberText
  getDocumentInfo(documentNumber)
  console.log('end updateDocumentNumber ')
}

const selectDocumentNumber = (index) => {
console.log('selectDocumentNumber   documentInfoList.value[index]');
console.log(documentInfoList.value[index])
  emits('select-document-number', documentInfoList.value[index]);
}


onMounted(() => {
  documentNumber.value = usePage().props.value.documentNumberProp
  selectedDocument.value=null;
  if (documentNumber.value) {
    getDocumentInfo(documentNumber)
  }



});

</script>

<template>
  <div className="flex flex-col min-h-screen overflow-hidden">

    <div v-if="documentInfoList.length==0 && !selectedDocument">
      <DocumentSearch @update-document-number="updateDocumentNumber" :loading="loading"></DocumentSearch>
    </div>
    <div v-else-if="documentInfoList.length >= 1">
      <DocumentInfoList @select-document-number="selectDocumentNumber" :documentInfoList="documentInfoList"></DocumentInfoList>
    </div>


  </div>
</template>

