<script setup>


import DropDown from '@/Components/Form/DropDown.vue'
import {defineEmits, onMounted, watch, reactive, toRaw, ref, computed} from "vue";
import axios from 'axios';
import {TrashIcon} from "@heroicons/vue/20/solid";
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import {CountrySelect, RegionSelect} from 'vue3-country-region-select';
import MediaLibraryCollection from "@/Components/Media/MediaLibraryCollection.vue";
import MediaLibraryAttachment from "@/Components/Media/MediaLibraryAttachment.vue";

import {
  Uploader,
} from '@spatie/media-library-pro-vue3';


const emit = defineEmits(['remove', 'reloadInfo', 'updateFormData'])

const props = defineProps({
  formData: {
    type: Object,
    required: false,
    default: null,
  },
  formErrors: {
    type: Object,
    required: false,
    default: null,
  },
  addEinSectionProp: {
    type: Boolean,
    required: true,
    default: false,
  },

})

const errors = ref(props.formErrors);
const validationErrors = ref('US');
const country = ref('US');
const region = ref('FL');
const birthday = ref();
const companyDocument = ref();

const taxIdentificationTypes = [
  {id: 1, name: 'EIN'},
  {id: 2, name: 'SSN/ITIN'}
];

const applicantIdentificationTypes = [
  {id: 1, name: 'State Drivers License'},
  {id: 2, name: 'State ID'},
  {id: 3, name: 'US Passport'},
  {id: 4, name: 'Foreign Passport'},
];


const applicant =
    {
      last_name: props.formData.last_name,
      isMinor: 'false',
      first_name: props.formData.first_name,
      dob: null,
      address: props.formData.address,
      city: props.formData.city,
      id_jurisdiction_country: null,
      id_jurisdiction_state: null,
      state: props.formData.state,
      zip: props.formData.zip,
      document_type: 'State Drivers License',
      document_id: null,
       document_image: null,
    };
const beneficiary =
    {
      last_name: props.formData.last_name,
      isMinor: 'false',
      first_name: props.formData.first_name,
      dob: null,
      address: props.formData.address,
      city: props.formData.city,
      id_jurisdiction_country: null,
      id_jurisdiction_state: null,
      state: props.formData.state,
      zip: props.formData.zip,
      document_type: 'State Drivers License',
      document_id: null,
      document_image: null,
    };


const applicantError =
    {
      last_name: null,
      isMinor: null,
      first_name: null,
      dob: null,
      address: null,
      city: null,
      country: null,
      id_jurisdiction_country: null,
      id_jurisdiction_state: null,
      state: null,
      zip: null,
      document_type: null,
      document_id: null,
      document_image: null,
      identificationType: null,
      identificationId: null,
    };


const form = reactive({
  tax_identification_type: 'EIN',
  tax_identification_id: null,
  city: null,
  state: null,
  zip: null,
  company_name: props.formData.company_name,
  company_address: props.formData.company_address,
  company_dba: null,
  receive_fincen_id: false,
  applicants: [],
  beneficiaries: [],
});


const submitForm = () => {
  emit('updateFormData', form);
}

const remove = () => {
  console.log('remove');
  emit('remove', true);
}

const addApplicant = () => {
  console.log('addApplicant');
  if (form.applicants.length < 4) {
    form.applicants.push(applicant);
    formErrors.boir.applicants.push(applicantError);
  }
}
const deleteApplicant = (index) => {

  console.log('deleteApplicant');

  if (index > -1 && index < form.applicants.length) {
    form.applicants.splice(index, 1);
    formErrors.boir.applicants.splice(index, 1); // Assuming you also want to remove the corresponding error
  } else {

  }


}
const addBeneficiary = () => {

  console.log('addBeneficiary');

  if (form.beneficiaries.length < 4) {
    form.beneficiaries.push(beneficiary);
    formErrors.boir.beneficiaries.push(applicantError);
  }
}


const deleteBeneficiary = (index) => {

  console.log('deleteBeneficiary');

  if (index > -1 && index < form.beneficiaries.length) {
    form.beneficiaries.splice(index, 1);
    formErrors.boir.beneficiaries.splice(index, 1); // Assuming you also want to remove the corresponding error
  } else {

  }


}

watch(form, (newForm) => {
  console.log('watch: newForm');
  console.log(newForm);
  emit('updateFormData', newForm);

}, {deep: true});


onMounted(() => {
  form.applicants.push(applicant);
  form.beneficiaries.push(beneficiary);

  console.log('form BOIR');
  console.log(form);
  emit('updateFormData', form);

});

</script>

<template>

  <div class="mt-10 border border-gray-200 pt-10 p-3">

    <div class="bg-indigo-500 text-white">
      <div class="float-right flex ">
        <button type="button"
                class="-m-0.0 flex p-2.5  hover:text-indigo-300 bg-indigo-500 text-white">

          <span class="sr-only">Remove</span>
          <TrashIcon @click="remove()" class="h-7 w-7" aria-hidden="true"/>
        </button>
      </div>
      <h2 class="text-xl font-bold uppercase text-center">Beneficial Ownership Information Report (BOIR)</h2>
      <h3 class="text-md font-medium text-center">(Required by Financial Crimes Enforcement Network.)</h3>
    </div>

    <div class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
      <div class="sm:col-span-2">
        <h2 class="text-md font-medium mt-2">1. Company Information</h2>
      </div>

      <div class="mt-1 sm:col-span-2">

        <label for="first-name" class="block text-sm font-medium text-gray-700">Company Name</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.company_name']}" v-model="form.company_name"
                 type="text" id="first-name" name="first-name" autocomplete="given-name"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.company_name']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.company_name']"
                  :key="index">{{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="mt-1 sm:col-span-2">
        <label for="first-name" class="block text-sm font-medium text-gray-700">Alternate name (e.g. trade name,
          DBA)</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.company_dba']}" v-model="form.company_dba"
                 type="text" id="first-name" name="first-name" autocomplete="given-name"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.company_dba']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.company_dba']"
                  :key="index">{{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>

<template v-if="!props.addEinSectionProp">

      <div>
        <DropDown :v-model="form.tax_identification_type" :values="taxIdentificationTypes"
                  :labelOn="'Company Tax Identification Type'"></DropDown>
      </div>
      <div>
        <label for="first-name" class="block text-sm font-medium text-gray-700">Company Tax Identification
          Number</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.tax_identification_id']}"
                 v-model="form.tax_identification_id"
                 type="text" id="first-name" name="first-name" autocomplete="given-name"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.tax_identification_id']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.tax_identification_id']"
                  :key="index">{{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
</template>

      <div class="sm:col-span-2">
        <label for="address" class="block text-sm font-medium text-gray-700">Actual Company's Physical Address</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.company_address.address']}"
                 v-model="form.company_address.address" type="text"
                 name="address" id="address" autocomplete="street-address"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.company_address.address']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.company_address.address']"
                  :key="index">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div>
        <label for="city" class="block text-sm font-medium text-gray-700">City</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.company_address.city']}"
                 v-model="form.company_address.city" type="text"
                 name="city" id="city" autocomplete="address-level2"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.company_address.city']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.company_address.city']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>


      <div>
        <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.company_address.state']}"
                 v-model="form.company_address.state" type="text"
                 name="region" id="region" autocomplete="address-level1"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.company_address.state']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.company_address.state']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div>
        <label for="postal-code" class="block text-sm font-medium text-gray-700">Postal Code</label>
        <div class="mt-1">
          <input :class="{'border-2 border-red-500': formErrors['boir.company_address.zip']}"
                 v-model="form.company_address.zip" type="text"
                 name="postal-code" id="postal-code" autocomplete="postal-code"
                 class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
          <div v-if="formErrors['boir.company_address.zip']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.company_address.zip']"
                  :key="index">{{
                  error
                }}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div>
      </div>

      <h2 class="text-md font-medium mt-2">2. Request to receive FinCEN ID?</h2>



      <div class="sm:col-span-2 mt-2">
        <div class="relative flex items-start">
          <div class="flex h-6 items-center">
          <div class="ml-3 text-sm leading-6">
            <label for="comments" class="font-medium text-gray-900">Request to receive FinCEN ID</label>
            <p id="comments-description" class="text-gray-500">Request to receive Fincen Identifier (Fincen ID): Check
              this box to receive a unique FincCEN identifier for your business. This number will be provided after the
              BOIR is accepted.</p>
          </div>
        </div>
        </div>
      </div>
      <div class="relative flex items-start mt-2">
        <div class="flex h-6 items-center">
          <input v-model="form.receive_fincen_id" value="true" id="small"
                 aria-describedby="small-description" name="entity" type="radio" checked
                 class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="small" class="font-medium text-gray-900">Yes</label>
        </div>
      </div>
      <div class="relative flex items-start mt-2">
        <div class="flex h-6 items-center">
          <input v-model="form.receive_fincen_id" value="false" id="medium"
                 aria-describedby="medium-description" name="entity" type="radio"
                 class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
        </div>
        <div class="ml-3 text-sm leading-6">
          <label for="medium" class="font-medium text-gray-900">No</label>
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="sm:col-span-2 flex justify-between items-center">
          <h2 class="text-md font-medium mt-2 sm:col-span-2">3. Applicant Information</h2>

          <button type="button"
                  class="rounded bg-emerald-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-emerald-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  @click="addApplicant()">
            Add Applicant
          </button>
        </div>


      </div>
    </div>


    <div v-for="(applicant, index) in form.applicants" :key="'applicant-' + index"
         class="mt-4 relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
      <div class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 ">
        <div class="sm:col-span-2 flex justify-between items-center">

          <div
              class="sm:col-span-2 flex justify-between items-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Applicant #{{ index + 1 }}

          </div>
          <button
              v-if="form.applicants.length > 1"
              type="button"
              class="rounded bg-[#e5e7eb] px-2 py-1 text-sm  shadow-sm hover:white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="deleteApplicant(index)">
            Delete Applicant
          </button>
        </div>
        <div><label for="city" class="block text-sm font-medium text-gray-700">Last Name</label>
          <div class="mt-1">
            <input
                v-model="form.applicants[index].last_name"
                :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.last_name']}"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                type="text" name="city" id="city" autocomplete="address-level2">
            <div v-if="formErrors['boir.applicants.'+index+'.last_name']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.applicants.'+index+'.last_name']"
                    :key="indexError">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div><label for="city" class="block text-sm font-medium text-gray-700">First Name</label>
          <div class="mt-1"><input
              v-model="form.applicants[index].first_name"
              :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.first_name']}"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              type="text" name="city" id="city" autocomplete="address-level2">
            <div v-if="formErrors['boir.applicants.'+index+'.first_name']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.applicants.'+index+'.first_name']"
                    :key="indexError">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="sm:col-span-2">
          <label for="address" class="block text-sm font-medium text-gray-700">Applicant Address</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.address']}"
                   v-model="form.applicants[index].address" type="text"
                   name="address" id="address" autocomplete="street-address"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.applicants.'+index+'.address']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.applicants.'+index+'.address']"
                    :key="indexError">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div>
          <label for="city" class="block text-sm font-medium text-gray-700">City</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.city']}"
                   v-model="form.applicants[index].city" type="text"
                   name="city" id="city" autocomplete="address-level2"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.applicants.'+index+'.city']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.applicants.'+index+'.city']"
                    :key="indexError">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div>
          <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.state']}"
                   v-model="form.applicants[index].state" type="text"
                   name="region" id="region" autocomplete="address-level1"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.applicants.'+index+'.state']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.applicants.'+index+'.state']"
                    :key="index">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div>
          <label for="region" class="block text-sm font-medium text-gray-700">Postal Code</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.zip']}"
                   v-model="form.applicants[index].zip" type="text"
                   name="region" id="region" autocomplete="address-level1"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.applicants.'+index+'.zip']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.applicants.'+index+'.zip']"
                    :key="index">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>  </div>

        <div><label for="city" class="block text-sm font-medium text-gray-700">Date of Birth</label>
          <div class="mt-1" :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.dob']}">
            <Datepicker v-model="form.applicants[index].dob" :enable-time-picker="false"/>
            <div v-if="formErrors['boir.applicants.'+index+'.dob']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm" v-for="(error, index) in formErrors['boir.applicants.'+index+'.dob']"
                    :key="index">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <DropDown v-model="form.applicants[index].document_type" :values="applicantIdentificationTypes"
                    :labelOn="'Applicant Identification type'"></DropDown>
        </div>
        <div>
          <label for="first-name" class="block text-sm font-medium text-gray-700">Applicant Identification Number</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.document_id']}"
                   v-model="form.applicants[index].document_id"
                   type="text" id="first-name" name="first-name" autocomplete="given-name"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.applicants.'+index+'.document_id']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.applicants.'+index+'.document_id']"
                    :key="index">{{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
          <label for="county" class="block text-sm font-medium text-gray-700">ID Jurisdiction Country</label>
          <div class="mt-1">
            <country-select
                :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.id_jurisdiction_country']}"
                v-model="form.applicants[index].id_jurisdiction_country"
                topCountry="US"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            ></country-select>


            <div v-if="formErrors['boir.applicants.'+index+'.id_jurisdiction_country']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, errorIndex) in formErrors['boir.applicants.'+index+'.id_jurisdiction_country']"
                    :key="errorIndex">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
 <label for="county" class="block text-sm font-medium text-gray-700">ID Jurisdiction State </label>
          <div class="mt-1">
            <region-select
                v-model="form.applicants[index].id_jurisdiction_state"
                :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.id_jurisdiction_state']}"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                :country="form.applicants[index].id_jurisdiction_country"
            ></region-select>
            <div v-if="formErrors['boir.applicants.'+index+'.id_jurisdiction_state']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, errorIndex) in formErrors['boir.applicants.'+index+'.id_jurisdiction_state']"
                    :key="errorIndex">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="sm:col-span-2">
          <label for="county" class="mb-2 block text-sm font-medium text-gray-700">Upload Applicant
            Identification (PDF, PNG, JPG, and JPEG are accepted.)</label>
          <div :class="{'border-2 border-red-500': formErrors['boir.applicants.'+index+'.document_image']}">
            {{ form.applicants[index].document_image }}
            <media-library-attachment
                v-model="form.applicants[index].document_image"
                route-prefix="media-library-pro"
                multiple="false"
                @change="onChange"
            ></media-library-attachment>
          </div>
          <div v-if="formErrors['boir.applicants.'+index+'.document_image']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm"
                  v-for="(error, errorIndex) in formErrors['boir.applicants.'+index+'.document_image']"
                  :key="errorIndex">
                {{ error }}
              </li>
            </ul>
          </div>

        </div>
      </div>
    </div>

    <div class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
      <div class="sm:col-span-2">
        <div class="sm:col-span-2 flex justify-between items-center">
          <h2 class="text-md font-medium mt-2 sm:col-span-2">4. Beneficiary Information</h2>

          <button type="button"
                  class="rounded bg-emerald-500 px-2 py-1 text-sm font-semibold text-white shadow-sm hover:bg-emerald-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  @click="addBeneficiary()">
            Add Beneficiary
          </button>
        </div>


      </div>
    </div>
    <div v-for="(beneficiary, index) in form.beneficiaries" :key="'beneficiary-' + index"
         class="mt-4 relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none">
      <div class="mt-2 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4 ">
        <div class="sm:col-span-2 flex justify-between items-center">

          <div
              class="sm:col-span-2 flex justify-between items-center text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Beneficiary #{{ index + 1 }}

          </div>
          <button
              v-if="form.beneficiaries.length > 1"
              type="button"
              class="rounded bg-[#e5e7eb] px-2 py-1 text-sm  shadow-sm hover:white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              @click="deleteBeneficiary(index)">
            Delete Beneficiary
          </button>
        </div>
        <div><label for="city" class="block text-sm font-medium text-gray-700">Last Name</label>
          <div class="mt-1">
            <input
                v-model="form.beneficiaries[index].last_name"
                :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.last_name']}"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                type="text" name="city" id="city" autocomplete="address-level2">
            <div v-if="formErrors['boir.beneficiaries.'+index+'.last_name']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.last_name']"
                    :key="indexError">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div><label for="city" class="block text-sm font-medium text-gray-700">First Name</label>
          <div class="mt-1"><input
              v-model="form.beneficiaries[index].first_name"
              :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.first_name']}"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              type="text" name="city" id="city" autocomplete="address-level2">
            <div v-if="formErrors['boir.beneficiaries.'+index+'.first_name']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.first_name']"
                    :key="indexError">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="sm:col-span-2">
          <h2 class="text-md font-medium mt-2">Is the Beneficiary a minor? (under 18 years old)</h2>
        </div>

        <div class="relative flex items-start">
          <div class="flex h-6 items-center">
            <input v-model="form.beneficiaries[index].isMinor" value="true" id="small"
                   aria-describedby="small-description" name="entity" type="radio" checked
                   class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="small" class="font-medium text-gray-900">Yes</label>
          </div>
        </div>
        <div class="relative flex items-start">
          <div class="flex h-6 items-center">
            <input v-model="form.beneficiaries[index].isMinor" value="false" id="medium"
                   aria-describedby="medium-description" name="entity" type="radio"
                   class="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600">
          </div>
          <div class="ml-3 text-sm leading-6">
            <label for="medium" class="font-medium text-gray-900">No</label>
          </div>
        </div>


        <div class="sm:col-span-2">
          <label for="address" class="block text-sm font-medium text-gray-700">Beneficiary Address</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.address']}"
                   v-model="form.beneficiaries[index].address" type="text"
                   name="address" id="address" autocomplete="street-address"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.address']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.address']"
                    :key="indexError">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div>
          <label for="city" class="block text-sm font-medium text-gray-700">City</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.city']}"
                   v-model="form.beneficiaries[index].city" type="text"
                   name="city" id="city" autocomplete="address-level2"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.city']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.city']"
                    :key="indexError">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div>
          <label for="region" class="block text-sm font-medium text-gray-700">State / Province</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.state']}"
                   v-model="form.beneficiaries[index].state" type="text"
                   name="region" id="region" autocomplete="address-level1"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.state']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.state']" :key="indexError">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div>
          <label for="region" class="block text-sm font-medium text-gray-700">Postal Code</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.zip']}"
                   v-model="form.beneficiaries[index].zip" type="text"
                   name="region" id="region" autocomplete="address-level1"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.zip']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm" v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.zip']"
                    :key="indexError">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div>  </div>


        <div><label for="city" class="block text-sm font-medium text-gray-700">Date of Birth</label>
          <div class="mt-1" :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.dob']}">
            <Datepicker v-model="form.beneficiaries[index].dob" :enable-time-picker="false"/>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.dob']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, index) in formErrors['boir.beneficiaries.'+index+'.dob']"
                    :key="index">{{
                    error
                  }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div></div>
        <div>
          <DropDown v-model="form.beneficiaries[index].document_type" :values="applicantIdentificationTypes"
                    :labelOn="'Beneficiary Identification type'"></DropDown>
        </div>
        <div>
          <label for="first-name" class="block text-sm font-medium text-gray-700">Beneficiary Identification
            Number</label>
          <div class="mt-1">
            <input :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.document_id']}"
                   v-model="form.beneficiaries[index].document_id"
                   type="text" id="first-name" name="first-name" autocomplete="given-name"
                   class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"/>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.document_id']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, indexError) in formErrors['boir.beneficiaries.'+index+'.document_id']"
                    :key="indexError">{{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="sm:col-span-1">
          <label for="county" class="block text-sm font-medium text-gray-700">ID Jurisdiction Country</label>
          <div class="mt-1">
            <country-select
                :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.id_jurisdiction_country']}"
                v-model="form.beneficiaries[index].id_jurisdiction_country"
                topCountry="US"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            ></country-select>


            <div v-if="formErrors['boir.beneficiaries.'+index+'.id_jurisdiction_country']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, errorIndex) in formErrors['boir.beneficiaries.'+index+'.id_jurisdiction_country']"
                    :key="errorIndex">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="sm:col-span-1">
          <label for="county" class="block text-sm font-medium text-gray-700">ID Jurisdiction State </label>
          <div class="mt-1">
            <region-select
                v-model="form.beneficiaries[index].id_jurisdiction_state"
                :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.id_jurisdiction_state']}"
                class="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                :country="form.beneficiaries[index].id_jurisdiction_country"
            ></region-select>
            <div v-if="formErrors['boir.beneficiaries.'+index+'.id_jurisdiction_state']" class="m-2">
              <ul>
                <li class="text-red-600 text-sm"
                    v-for="(error, errorIndex) in formErrors['boir.beneficiaries.'+index+'.id_jurisdiction_state']"
                    :key="errorIndex">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </div>


        <div class="sm:col-span-2">
          <label for="county" class="mb-2 block text-sm font-medium text-gray-700">Upload Beneficiary
            Identification (PDF, PNG, JPG, and JPEG are accepted.)</label>
          <div :class="{'border-2 border-red-500': formErrors['boir.beneficiaries.'+index+'.document_image']}">
            {{ form.beneficiaries[index].document_image }}
            <media-library-attachment
                v-model="form.beneficiaries[index].document_image"
                route-prefix="media-library-pro"
                multiple="false"
            ></media-library-attachment>
          </div>
          <div v-if="formErrors['boir.beneficiaries.'+index+'.document_image']" class="m-2">
            <ul>
              <li class="text-red-600 text-sm"
                  v-for="(error, errorIndex) in formErrors['boir.beneficiaries.'+index+'.document_image']"
                  :key="errorIndex">
                {{ error }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

