<script setup>
import {computed, watch} from 'vue';
import {CheckIcon} from '@heroicons/vue/24/solid'
import {defineEmits, onMounted, ref} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

const emits = defineEmits(['update-current-step']);


const props = defineProps({
  currentStepProp: {
    type: Number,
    required: false,
    default: 1,


  },

})
const steps = ref([
  {
    id: '1',
    name: 'Enter Company Information',
    description: 'Enter the Company Document Number',
    href: '#',
    status: 'current',
    active: true,
  },
  {
    id: '2',
    name: 'Order Documents',
    description: 'Select the Order Options',
    href: '#',
    status: 'upcoming',
    active: false
  },
  {
    id: '3',
    name: 'Submit Order',
    description: 'Submit the Order For Processing.',
    href: '#',
    status: 'upcoming',
    active: false
  },
]);


const currentStep = ref(props.currentStepProp);


const updateSteps = (toStep) => {
  console.log('updateSteps toStep: ' + toStep);

  console.log("steps.value[toStep - 1].status == complete");
  console.log(steps.value[toStep - 1].status == 'complete');
  if (steps.value[toStep - 1].status == 'complete'||steps.value[toStep - 1].status == 'current') {
    currentStep.value = toStep;
  } else {
    steps.value[currentStep.value - 1].status = 'current';
  }


  console.log('steps.value.length');
  console.log(steps.value.length);
  for (let i = 0; i < steps.value.length; i++) {
    console.log('i');
    console.log(i);
    steps.value[i].active = false;
  }
  steps.value[toStep - 1].active = true;

  console.log(toStep+'steps.value[toStep-1].active');
    console.log(steps.value[toStep-1].active);
    // steps.value[i - 1].status = 'complete';



  emits('update-current-step', currentStep.value);

}
watch(() => props.currentStepProp, (newValue, oldValue) => {
  // Custom logic based on prop change
  console.log('currentStepProp changed:', newValue, oldValue);

  if (newValue>oldValue) {
    steps.value[oldValue - 1].status = 'complete';
    steps.value[newValue - 1].status = 'current';
  } else {
    steps.value[oldValue - 1].status = 'upcoming';
    steps.value[newValue - 1].status = 'current';
  }

  for (let i = 0; i < steps.value.length; i++) {
console.log('steps.value[i].status');
console.log(steps.value[i].status);
    if ((i + 1) < newValue) {
      console.log('<');
      steps.value[i].status == 'complete';
    } else if ((i + 1) == newValue) {
      console.log('=');
      steps.value[i].status == 'current';
    } else {
      // steps.value[i].status = 'upcoming';
    }
    console.log(steps.value);
  }
  console.log('currentStepProp changed:', newValue, oldValue);

  updateSteps(newValue);

});

onMounted(() => {
  console.log('currentStepProp');
  console.log(usePage().props.value.currentStepProp);
  console.log(props.currentStepProp);

  if (props.currentStepProp) {
    for (let i = 0; i < props.currentStepProp; i++) {
      steps.value[i].status = 'complete';
    }
    steps.value[props.currentStepProp - 1].status = 'current';
    steps.value[props.currentStepProp - 1].active = true;
  }


  updateSteps(props.currentStepProp);


})

</script>
<template>

  <div class="lg:border-b lg:border-t lg:border-gray-200">
    <nav class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8" aria-label="Progress">
      <ol role="list"
          class="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200">
        <li v-for="(step, stepIdx) in steps" :key="step.id" class="relative overflow-hidden lg:flex-1">
          <div
              :class="[stepIdx === 0 ? 'rounded-t-md border-b-0' : '', stepIdx === steps.length - 1 ? 'rounded-b-md border-t-0' : '', 'overflow-hidden border border-gray-200 lg:border-0']">

            <a v-if="step.status === 'complete'" @click="updateSteps(step.id)" class="group">
               <span v-if="step.active"
                     class="bg-indigo-600 absolute left-0 top-0 h-full w-1  lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                     aria-hidden="true"/>
              <span :class="{'group-hover:bg-gray-200': !step.active}"
                  class="absolute left-0 top-0 h-full w-1 bg-transparent  lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                  aria-hidden="true"/>
              <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-start px-6 py-5 text-sm font-medium']">
                <span class="flex-shrink-0">
                  <span class="flex h-10 w-10 items-center justify-center rounded-full bg-indigo-600">
                    <CheckIcon class="h-6 w-6 text-white" aria-hidden="true"/>
                  </span>
                </span>
                <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span class="text-sm font-medium">{{ step.name }}</span>
                  <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                </span>
              </span>
            </a>
            <a v-else-if="step.status === 'current'" @click="updateSteps(step.id)" aria-current="step">
              <span v-if="step.active"
                    class="bg-indigo-600 absolute left-0 top-0 h-full w-1  lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                    aria-hidden="true"/>
              <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-start px-6 py-5 text-sm font-medium']">
                <span class="flex-shrink-0">
                  <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-indigo-600">
                    <span class="text-indigo-600">{{ step.id }}</span>
                  </span>
                </span>
                <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span class="text-sm font-medium text-indigo-600">{{ step.name }}</span>
                  <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                </span>
              </span>
            </a>
            <a v-else class="group">
              <span
                  class="absolute left-0 top-0 h-full w-1 bg-transparent group-hover:bg-gray-200 lg:bottom-0 lg:top-auto lg:h-1 lg:w-full"
                  aria-hidden="true"/>
              <span :class="[stepIdx !== 0 ? 'lg:pl-9' : '', 'flex items-start px-6 py-5 text-sm font-medium']">
                <span class="flex-shrink-0">
                  <span class="flex h-10 w-10 items-center justify-center rounded-full border-2 border-gray-300">
                    <span class="text-gray-500">{{ step.id }}</span>
                  </span>
                </span>
                <span class="ml-4 mt-0.5 flex min-w-0 flex-col">
                  <span class="text-sm font-medium text-gray-500">{{ step.name }}</span>
                  <span class="text-sm font-medium text-gray-500">{{ step.description }}</span>
                </span>
              </span>
            </a>
            <template v-if="stepIdx !== 0">
              <!-- Separator -->
              <div class="absolute inset-0 left-0 top-0 hidden w-3 lg:block" aria-hidden="true">
                <svg class="h-full w-full text-gray-300" viewBox="0 0 12 82" fill="none" preserveAspectRatio="none">
                  <path d="M0.5 0V31L10.5 41L0.5 51V82" stroke="currentcolor" vector-effect="non-scaling-stroke"/>
                </svg>
              </div>
            </template>
          </div>
        </li>
      </ol>
    </nav>
  </div>
</template>
